.allowed{
    cursor: pointer;
}
.not-allowed{
    cursor: not-allowed;
}
.circle-color{
    height: 20px;
    width: 100%;
}
